import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export function TermsUseBuy() {
  const { t } = useTranslation('components/terms-use');

  return (
    <div className='flex items-center gap-2'>
      <div>
        <svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M3.11111 0C1.393 0 0 1.393 0 3.11112V10.8889C0 12.607 1.393 14 3.11111 14H10.8889C12.607 14 14 12.607 14 10.8889V3.11112C14 1.393 12.607 0 10.8889 0H3.11111ZM7 3.11112C7.42933 3.11112 7.77778 3.45957 7.77778 3.8889C7.77778 4.31824 7.42933 4.66668 7 4.66668C6.57067 4.66668 6.22222 4.31824 6.22222 3.8889C6.22222 3.45957 6.57067 3.11112 7 3.11112ZM7 5.44446C7.42933 5.44446 7.77778 5.79291 7.77778 6.22224V10.1111C7.77778 10.5405 7.42933 10.8889 7 10.8889C6.57067 10.8889 6.22222 10.5405 6.22222 10.1111V6.22224C6.22222 5.79291 6.57067 5.44446 7 5.44446Z' fill='#53535F' />
        </svg>
      </div>
      <p className='text-left text-sm font-medium leading-[140%] text-[#97979B]'>
        {t('buy.1')}{' '}
        <Link href='/terms/terms_of_use' className='text-[#e0e0e0] underline'>
          {t('buy.2')}
        </Link>
      </p>
    </div>
  );
}
