enum GatewaysEnum {
  PAGARME = 14,
  PAGBANK = 1,
  PAYPAL = 2,
  PICPAY = 20,
  COINPAYMENTS = 25,
  MERCADOPAGO = 5,
  STRIPE = 11,
  PADDLE = 28,
  KIRVANO = 27,
}

export default GatewaysEnum;
