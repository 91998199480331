/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import 'react-toastify/dist/ReactToastify.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCardElement, StripeCardNumberElement, StripeElementStyle } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Button from '@/common/button';
import { getCookie } from '@/configs/sdk';
import { useAuth } from '@/hooks/use-auth';
import type { CreditCardProps } from '@/interface/credit-card';
import type { AppDispatch } from '@/store';
import { checkoutStripe, checkoutStripeTrial } from '@/store/stripe';

import { TermsUseBuy } from '../pricing/terms-use-buy';

interface FormData {
  cardHolder: string;
}

interface TrialProps {
  trialDays?: number;
  valuePlan?: string;
  frequencyPlan?: string;
}

interface StripeCreditCardProps extends CreditCardProps, TrialProps {}

const StripeCreditCard = ({ gateway_id, plan_id, currency_id, isTrial, trialDays = 7, valuePlan, frequencyPlan }: StripeCreditCardProps) => {
  const auth = useAuth();
  const { t } = useTranslation('components/checkout');
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const defaultValues = {
    cardHolder: '',
  };

  const schema = yup.object().shape({
    cardHolder: yup.string().required(t('components/checkout:error_message.required.card_holder')),
  });

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (data: FormData) => {
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const card: StripeCardElement | StripeCardNumberElement | null = elements.getElement(CardElement);

    if (card === null) {
      toast.error(`Invalid card.`, {
        position: 'top-center',
      });
      setLoading(false);
      return;
    }

    const { error, token } = await stripe.createToken(card, {
      name: data.cardHolder,
    });

    if (error) {
      toast.error(`Invalid card.`, {
        position: 'top-center',
      });
      setLoading(false);
      return;
    }
    const clickId = getCookie();
    const bodyCheckout = {
      gateway_id,
      plan_id,
      installments: 1,
      currency_id,
      coupon: '',
      ref: '',
      cc_token: token?.id,
      user_doc: '',
      user_phone: '',
      credit_card: '',
      payment_method: '',
      boleto: '',
      click_id: clickId,
    };

    await dispatch(isTrial ? checkoutStripeTrial(bodyCheckout) : checkoutStripe(bodyCheckout))
      .then((res) => {
        // @ts-ignore
        if (res?.payload?.status !== 200) {
          // @ts-ignore
          if (res?.payload?.error?.data) {
            // @ts-ignore
            toast.error(res?.payload?.error?.data, {
              position: 'top-center',
            });
            setLoading(false);
            return;
          }

          // @ts-ignore
          toast.error(`Internal error, please contact support.`, {
            position: 'top-center',
          });
          setLoading(false);
          return;
        }

        toast.success(`${t('checkout_success')}`);
        const purchase = {
          gateway_id,
          plan_id,
          currency_id,
        };
        localStorage.setItem('purchase', JSON.stringify(purchase));
        auth.me();
        if (isTrial) {
          const dataPlanTrial = {
            daysTrial: trialDays,
            planValue: valuePlan,
            frequencyPlan,
          };
          sessionStorage.setItem('trialApproved', 'true');
          sessionStorage.setItem('dataTrial', JSON.stringify(dataPlanTrial));
        }
        router.push('/thankyou');
      })
      .catch(() => {
        toast.error(`Internal error, please contact support.`, {
          position: 'top-center',
        });
        setLoading(false);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  const cardStyle: StripeElementStyle = {
    base: {
      fontSmoothing: 'antialiased',
      iconColor: '#fff',
      fontFamily: `'Bai Jamjuree', sans-serif`,
      fontSize: '14px',
      textTransform: 'uppercase',
      padding: '16px 40px',
      color: '#D6E4EF',
      fontWeight: 500,
      backgroundColor: '#181919',
      '::placeholder': {
        color: '#D6E4EF',
        fontWeight: 500,
      },
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col'>
          <div className='flex w-full flex-col gap-2.5'>
            <input type='text' placeholder={t('placeholder.holder_name')} className='w-full rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 text-sm font-medium uppercase text-[#D6E4EF] outline-0 placeholder:font-medium placeholder:text-[#D6E4EF] focus:outline-none' {...register('cardHolder')} />
          </div>
          {errors.cardHolder && <span className='ml-1 mt-2 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.cardHolder?.message}</span>}
        </div>

        <CardElement options={{ style: cardStyle, hidePostalCode: true, disableLink: true }} className='stripe-card rounded-xl bg-[#181919]' />

        {router.pathname === '/pricing' && (
          <div className='hidden gap-4'>
            <div className='flex w-full items-center rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 lg:w-3/4'>
              <input type='text' placeholder='Discount Coupon' className='w-full bg-transparent text-left text-sm uppercase text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:text-[#D6E4EF] focus:outline-none' />
            </div>
            <button className='w-1/3 rounded-xl bg-[#879BAB] py-4 text-center text-white mobile:px-10'>
              <span className='font-bold uppercase'>APPLY</span>
            </button>
          </div>
        )}
        <div className='flex w-full items-center gap-2'>
          <Button type='submit' id={isTrial ? 'click_trial_stripe_credit_card' : 'click_stripe_credit_card'} className='!w-full rounded-xl bg-green-300 py-4 text-center text-black mobile:px-10' loading={loading}>
            <span className='pointer-events-none font-bold uppercase'>{isTrial ? t('placeholder.button_trial') : t('placeholder.button')}</span>
          </Button>
        </div>

        {!isTrial && <TermsUseBuy />}
      </div>
    </form>
  );
};
export default StripeCreditCard;
