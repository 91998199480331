import 'react-toastify/dist/ReactToastify.css';

import type { Paddle } from '@paddle/paddle-js';
import { CheckoutEventNames, initializePaddle } from '@paddle/paddle-js';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import EnvironmentsEnum from '@/enums/environments-enum';
import { useAuth } from '@/hooks/use-auth';

import { TermsUseBuy } from '../pricing/terms-use-buy';

interface TrialProps {
  trialDays?: number;
  valuePlan?: string;
  frequencyPlan?: string;
}
export interface PaddleCreditCardProps extends TrialProps {
  gateway_id: number;
  plan_id: number;
  currency_id: number;
  isTrial: number;
  gatewayPlanId?: {
    gateway_plan_id: string;
  };
}

export function PaddleCreditCard({ isTrial, gatewayPlanId, gateway_id, plan_id, currency_id, trialDays = 7, valuePlan, frequencyPlan }: PaddleCreditCardProps) {
  const [paddle, setPaddle] = useState<Paddle>();
  const { t } = useTranslation('components/checkout');
  const { user } = useAuth();
  const enumPaddle = CheckoutEventNames;

  useEffect(() => {
    const envContext = process.env.NEXT_PUBLIC_ENV ?? '';
    const environment = Object.values(EnvironmentsEnum).includes(envContext as EnvironmentsEnum) ? (envContext as EnvironmentsEnum) : EnvironmentsEnum.Sandbox;

    const token = process.env.NEXT_PUBLIC_PADDLE_TOKEN ?? '';

    initializePaddle({
      environment,
      token,
      eventCallback(data) {
        if (data.name === enumPaddle.CHECKOUT_COMPLETED && isTrial) {
          const dataPlanTrial = {
            daysTrial: trialDays,
            planValue: valuePlan,
            frequencyPlan,
          };
          sessionStorage.setItem('trialApproved', 'true');
          sessionStorage.setItem('dataTrial', JSON.stringify(dataPlanTrial));
        }
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  function getLocale() {
    return navigator.language;
  }

  function openCheckout() {
    paddle?.Checkout.open({
      items: [{ priceId: gatewayPlanId?.gateway_plan_id ?? ' ', quantity: 1 }],
      settings: {
        displayMode: 'inline',
        frameTarget: 'checkout-container',
        frameInitialHeight: 450,
        frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none; color: #D6E4EF !important',
        allowedPaymentMethods: ['card'],
        theme: 'dark',
        locale: getLocale(),
        successUrl: 'https://noping.com/thankyou',
      },
      customData: {
        user_id: `${user?.id}`,
      },
    });
  }
  const isUserLogged = () => {
    return !!user;
  };

  const handleCheckout = () => {
    const hasUser = isUserLogged();

    if (hasUser) {
      openCheckout();
      return;
    }
    toast.error(`Needs to be logged!`, {
      position: 'top-center',
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const purchase = {
      gateway_id,
      plan_id,
      currency_id,
    };
    localStorage.setItem('purchase', JSON.stringify(purchase));
    handleCheckout();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className='flex flex-col gap-5'>
        <div className='checkout-container' />
        <button type='submit' id={isTrial ? 'click_trial_paddle_credit_card' : 'click_paddle_credit_card'} className='w-full rounded-xl bg-green-300 py-4 text-center text-black mobile:px-10'>
          <span className='pointer-events-none font-bold uppercase'>{isTrial ? t('placeholder.button_trial') : t('placeholder.button')}</span>
        </button>
        <TermsUseBuy />
      </div>
    </form>
  );
}
