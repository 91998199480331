import type { PayPalButtonsComponentProps } from '@paypal/react-paypal-js';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import GatewaysEnum from '@/enums/gateways-enum';
import { useAuth } from '@/hooks/use-auth';

interface TrialProps {
  trialDays?: number;
  valuePlan?: string;
  frequencyPlan?: string;
  isTrial?: boolean;
}

interface PayPalProps extends TrialProps {
  currencyCode: string;
  gateways: [];
}

export default function PayPal({ currencyCode, gateways, isTrial = false, trialDays = 7, valuePlan, frequencyPlan }: PayPalProps) {
  const [gatewayPlanId, setGatewayPlanId] = useState('');
  const router = useRouter();
  const { user, me } = useAuth();

  useEffect(() => {
    // @ts-ignore
    const planId = gateways.find((gateway) => gateway.gateway_id === GatewaysEnum.PAYPAL);
    if (planId) {
      // @ts-ignore
      setGatewayPlanId(planId.gateway_plan_id);
    }
  }, [gateways]);

  const checkUser = () => {
    if (!user) {
      return false;
    }
    return true;
  };

  const createSubscription: PayPalButtonsComponentProps['createSubscription'] = async (_data, actions) => {
    const orderId = await actions.subscription.create({
      plan_id: gatewayPlanId,
      custom_id: `btn.sub_user_${user?.id}`,
    });
    return orderId;
  };

  const onClick = (_data: any, actions: any) => {
    const hasUser = checkUser();
    const clickPaypalElement = document.getElementById('click_paypal');
    if (clickPaypalElement) {
      clickPaypalElement.click();
    }
    if (!hasUser) {
      toast.error('Needs to be logged in to checkout!', {
        position: 'top-center',
      });
      return actions.reject();
    }
    return actions.resolve();
  };

  const onApprove: PayPalButtonsComponentProps['onApprove'] = async () => {
    if (isTrial) {
      const dataPlanTrial = {
        daysTrial: trialDays,
        planValue: valuePlan,
        frequencyPlan,
      };
      sessionStorage.setItem('trialApproved', 'true');
      sessionStorage.setItem('dataTrial', JSON.stringify(dataPlanTrial));
    }
    me();
    router.push('/thankyou');
  };

  return gatewayPlanId ? (
    <PayPalScriptProvider
      options={{
        clientId: process?.env?.NEXT_PUBLIC_PAYPAL_CLIENT_ID ?? '',
        components: 'buttons',
        intent: 'subscription',
        currency: currencyCode,
        vault: true,
      }}
    >
      <PayPalButtons createSubscription={createSubscription} style={{ layout: 'horizontal', disableMaxWidth: true, height: 45 }} onClick={onClick} onApprove={onApprove} />
      <button type='button' id='click_paypal' style={{ display: 'none' }} />
    </PayPalScriptProvider>
  ) : (
    <div className='mt-2 h-11 w-36 animate-pulse cursor-pointer rounded-xl border-[3px] border-transparent bg-[#26262A] p-5' />
  );
}
