import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

// Ação assíncrona para buscar dados dos planos usando a instância do Axios com credenciais
export const getPlans = createAsyncThunk('appPlan/getPlans', async () => {
  const response = await axiosInstance.get('/plans');
  return response.data;
});

export const getPlansTrial = createAsyncThunk('appPlan/getPlansTrial', async () => {
  const response = await axiosInstance.get('/plans/trial');
  return response.data;
});

// Cria o slice do Redux para gerenciar o estado dos planos
export const appPlanSlice = createSlice({
  name: 'appPlan',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appPlanSlice.reducer;
