/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import 'react-toastify/dist/ReactToastify.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Button from '@/common/button';
import { getCookie } from '@/configs/sdk';
import { useAuth } from '@/hooks/use-auth';
import type { CreditCardProps } from '@/interface/credit-card';
import type { AppDispatch } from '@/store';
import { checkoutPagarme, checkoutPagarmeTrial, validateCard } from '@/store/pagarme';
import { MaskCPF } from '@/utils/mask-cpf';

import { TermsUseBuy } from '../pricing/terms-use-buy';

interface FormData {
  cardHolder: string;
  cardNumber: string;
  expireDate: string;
  cvcCode: string;
  cpf: string;
}

interface TrialProps {
  trialDays?: number;
  valuePlan?: string;
  frequencyPlan?: string;
}
interface PagarmeCreditCardProps extends CreditCardProps, TrialProps {}

const PagarmeCreditCard = ({ gateway_id, plan_id, currency_id, isTrial, trialDays = 7, valuePlan, frequencyPlan }: PagarmeCreditCardProps) => {
  const auth = useAuth();
  const { t } = useTranslation('components/checkout');
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues = {
    cardHolder: '',
    cardNumber: '',
    expireDate: '',
    cvcCode: '',
    gateway_id,
    plan_id,
    currency_id,
    coupon: null,
    cpf: '',
  };

  const schema = yup.object().shape({
    cardHolder: yup.string().required(t('components/checkout:error_message.required.card_holder')),
    cardNumber: yup.string().max(23, t('components/checkout:error_message.max.card_number')).required(t('components/checkout:error_message.required.card_number')),
    expireDate: yup.string().required(t('components/checkout:error_message.required.expire_date')),
    cvcCode: yup.string().required(t('components/checkout:error_message.required.cvc')),
    cpf: yup.string().required(t('components/checkout:error_message.required.cpf')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  function formatCPF(event: string) {
    const formattedCPF = MaskCPF(event);
    setValue('cpf', formattedCPF);
  }

  async function handleCheckout(creditCard: FormData, cardToken: string) {
    const clickId = getCookie();
    const body = {
      gateway_id,
      plan_id,
      installments: 1,
      currency_id,
      ref: '',
      coupon: '',
      credit_card: '',
      user_doc: creditCard.cpf,
      user_phone: '',
      payment_method: '',
      boleto: '',
      cc_token: cardToken,
      click_id: clickId,
      card: {
        card_holder_name: creditCard.cardHolder,
        card_number: creditCard.cardNumber,
        card_expiration_date: creditCard.expireDate,
        card_cvv: creditCard.cvcCode,
      },
    };

    await dispatch(isTrial ? checkoutPagarmeTrial(body) : checkoutPagarme(body))
      .then((res) => {
        // @ts-ignore
        if (res?.payload?.status !== 200) {
          // @ts-ignore
          if (res?.payload?.error?.data) {
            // @ts-ignore
            toast.error(res?.payload?.error?.data, {
              position: 'top-center',
            });
            setLoading(false);
            return;
          }
          // @ts-ignore
          toast.error(`Internal error, please contact support.`, {
            position: 'top-center',
          });
          setLoading(false);
          return;
        }

        toast.success(`${t('checkout_success')}`);
        const purchase = {
          gateway_id,
          plan_id,
          currency_id,
        };
        localStorage.setItem('purchase', JSON.stringify(purchase));
        auth.me();
        if (isTrial) {
          const dataPlanTrial = {
            daysTrial: trialDays,
            planValue: valuePlan,
            frequencyPlan,
          };
          sessionStorage.setItem('trialApproved', 'true');
          sessionStorage.setItem('dataTrial', JSON.stringify(dataPlanTrial));
        }
        router.push('/thankyou');
      })
      .catch(() => {
        toast.error(`Internal error, please contact support.`, {
          position: 'top-center',
        });
        setLoading(false);
      });
  }

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    await dispatch(validateCard(data))
      .then((res) => {
        // @ts-ignore
        if (!res?.payload?.success) {
          // @ts-ignore
          toast.error(`Cartão inválido`, {
            position: 'top-center',
          });
          setLoading(false);
          return;
        }
        const { hash } = res.payload;
        handleCheckout(data, hash);
      })
      .catch(() => {
        toast.error(`Internal error, please contact support.`, {
          position: 'top-center',
        });
        setLoading(false);
      });
  };

  const formatCardNumber = (value: string) => {
    let onlyDigits = value.replace(/\D/g, '');

    if (onlyDigits.length > 16) {
      onlyDigits = onlyDigits.substring(0, 16);
    }

    const formatted = onlyDigits.replace(/(.{4})/g, '$1 ').trim();
    return formatted;
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCardNumber(e.target.value);
    setValue('cardNumber', formattedValue);
  };

  function formatExpireDate(value: string) {
    let formattedValue = value.replace(/[^0-9]/g, '');

    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
    }

    formattedValue = formattedValue.slice(0, 5);
    setValue('expireDate', formattedValue);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col gap-4'>
        <div className='flex w-full flex-col gap-1'>
          <input type='text' placeholder={t('placeholder.holder_name')} className='w-full rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 text-sm font-medium uppercase text-[#D6E4EF] outline-0 placeholder:font-medium placeholder:text-[#D6E4EF] focus:outline-none' {...register('cardHolder')} />
          {errors.cardHolder && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.cardHolder?.message}</span>}
        </div>

        <div className='flex flex-wrap items-stretch gap-4 laptop:flex-row laptop:flex-nowrap laptop:gap-2'>
          <div className='flex w-full flex-col gap-1 text-white'>
            <input type='text' placeholder={t('placeholder.card_number')} className='w-full rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 text-sm font-medium uppercase text-[#D6E4EF] outline-0 placeholder:font-medium placeholder:text-[#D6E4EF] focus:outline-none' {...register('cardNumber')} onChange={handleCardNumberChange} />
            {errors.cardNumber && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.cardNumber?.message}</span>}
          </div>

          <div className='flex w-full flex-col gap-1 laptop:w-1/4'>
            <input type='text' placeholder='MM/YY' className='w-full rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 text-sm font-medium uppercase text-[#D6E4EF] outline-0 placeholder:font-medium placeholder:text-[#D6E4EF] focus:outline-none laptop:text-center' {...register('expireDate')} onChange={(event) => formatExpireDate(event.target.value)} minLength={5} maxLength={5} />
            {errors.expireDate && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.expireDate?.message}</span>}
          </div>

          <div className='flex w-full flex-col gap-1 laptop:w-1/4'>
            <input type='text' placeholder='CVC' className='w-full rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 text-left text-sm font-medium uppercase text-[#D6E4EF] outline-0 placeholder:font-medium placeholder:text-[#D6E4EF] focus:outline-none laptop:text-center' {...register('cvcCode')} minLength={3} maxLength={4} />
            {errors.cvcCode && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.cvcCode?.message}</span>}
          </div>
        </div>

        <div className='flex w-full flex-col gap-1'>
          <input type='text' placeholder={t('placeholder.cpf')} className='w-full rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 text-sm font-medium uppercase text-[#D6E4EF] outline-0 placeholder:font-medium placeholder:text-[#D6E4EF] focus:outline-none' {...register('cpf')} onChange={(event) => formatCPF(event.target.value)} maxLength={14} />
          {errors.cpf && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.cpf?.message}</span>}
        </div>

        {router.pathname === '/pricing' && (
          <div className='hidden gap-2'>
            <div className='flex w-full items-center rounded-xl border border-[#49545D] bg-[#181919] px-10 py-4 lg:w-3/4'>
              <input type='text' placeholder='Discount Coupon' className='w-full bg-transparent text-left text-sm uppercase text-[#D6E4EF] outline-0 placeholder:font-medium placeholder:text-[#D6E4EF] focus:outline-none' />
            </div>
            <button className='w-1/3 rounded-xl bg-[#879BAB] py-4 text-center text-white mobile:px-10'>
              <span className='font-bold uppercase'>APPLY</span>
            </button>
          </div>
        )}

        <div className='flex w-full items-center gap-2'>
          <Button type='submit' id={isTrial ? 'click_trial_pagarme_credit_card' : 'click_pagarme_credit_card'} className='!w-full rounded-xl bg-green-300 py-4 text-center text-black mobile:px-10' loading={loading}>
            <span className='pointer-events-none font-bold uppercase'>{isTrial ? t('placeholder.button_trial') : t('placeholder.button')}</span>
          </Button>
        </div>

        <TermsUseBuy />
      </div>
    </form>
  );
};

export default PagarmeCreditCard;
